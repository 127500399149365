@font-face {
    font-family: 'Wedges';
    src: url('../fonts/Wedges.ttf') format('truetype');
}

.about {
    position: relative; /* Add relative positioning to the container */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2em;
    font-family: 'Wedges', sans-serif;
    font-size: 2em; /* Adjust the font size to be big */
    background-color: #b18565; /* Add a background color */
    margin: 20px auto; /* Center the component with margins */
    width: 80%; /* Adjust width as needed */
    border-radius: 10px; /* Add border radius */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); /* Add a box shadow */
    color: #fff; /* Set text color to white */
}

.about img {
    position: absolute; /* Position the image absolutely */
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    width: 600px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

.about p {
    margin: 1em 0; /* Add margin to paragraphs */
    line-height: 1.5; /* Adjust line height for readability */
    color: #fff; /* Set text color to white */
}

.headingA {
    font-size: 2em; /* Make the font size bigger */
    text-shadow: 5px 5px 15px rgba(245, 220, 188, 0.8); /* Beige text shadow */
}

.smollcat {
    color: #00FFCC; /* Set the specific color */
}

.smol {
    color: #00FFCC; /* Set the specific color */
}

.solana {
    background: linear-gradient(to right, #42c0ad, #42c0ad, #5fa1bd, #5fa1bd, #9d5fdf, #9d5fdf, #9d5fdf); /* Gradient for SOLANA */
    -webkit-background-clip: text;
    color: transparent;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .about {
        padding: 1em;
        font-size: 1.5em; /* Adjust font size for smaller screens */
        width: 90%;
    }

    .about img {
        top: 5px; /* Adjust position for smaller screens */
        left: 5px; /* Adjust position for smaller screens */
        width: 400px; /* Adjust size for smaller screens */
    }

    .headingA {
        font-size: 1.8em; /* Adjust heading size */
    }
}

@media (max-width: 480px) {
    .about {
        padding: 0.5em;
        font-size: 1.2em; /* Adjust font size for smaller screens */
        width: 100%;
    }

    .about img {
        top: -10px; /* Adjust position for smaller screens */
        left: -85px; /* Adjust position for smaller screens */
        width: 300px; /* Adjust size for smaller screens */
    }

    .headingA {
        font-size: 1.5em; /* Adjust heading size */
    }
}
