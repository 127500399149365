@font-face {
    font-family: 'Wedges';
    src: url('../fonts/Wedges.ttf') format('truetype');
}

.hero {
    text-align: center;
    padding: 2em;
    background-size: cover;
    position: relative;
    font-family: 'Wedges', sans-serif; /* Apply the Wedges font to the hero class */
}

.speech-bubble {
    position: relative;
    display: inline-block;
    margin-bottom: 1em;
}

.bubble-img {
    width: 200px; /* Adjust size as needed */
    height: auto;
}

.bubble-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    font-size: 1.2em;
    color: black; /* Change color if needed */
    text-align: center; /* Ensure text is centered */
}

.cat-link {
    display: block;
    position: relative;
    z-index: 1;
}

.cat-image {
    width: 500px; /* Adjust size to make the cat bigger */
    height: auto;
    position: absolute;
    top: 50%; /* Adjust this value to position the cat */
    left: 50%;
    transform: translate(-50%, -55%);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.cat-link:hover .cat-image {
    animation: jump 0.5s infinite;
}

@keyframes jump {
    0%, 100% {
        transform: translate(-50%, -55%);
    }
    50% {
        transform: translate(-50%, -65%);
    }
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 100px; /* Adjust to create space below the cat image */
}

.buy-nows, .view-chart {
    background-color: #00C851;
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Wedges', sans-serif; /* Ensure Wedges font is applied */
}

.view-chart {
    background-color: #33b5e5;
}

.contract-address {
    margin-bottom: 1em;
    color: white; /* Change text color to white */
    background-color: #b18565; /* Add background color brown */
    display: inline-block; /* Ensure the box fits the content */
    padding: 0.5em 1em; /* Add padding for better appearance */
    margin-top: 20px;
    border-radius: 20px; /* Optional: Add border radius for rounded corners */
    width: auto; /* Set width to auto */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 1); /* Add angled drop shadow */
    cursor: pointer; /* Indicate that the element is clickable */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.contract-address:hover {
    background-color: #8e6e50; /* Slightly darker background on hover */
}

.token-info {
    display: flex;
    justify-content: space-around;
    background-color: #b18565;
    color: white;
    padding: 0.5em; /* Make the box smaller */
    border-radius: 10px;
    width: 500px; /* Set a specific width */
    margin: 20px auto 0 auto; /* Center the box horizontally and add top margin */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 1); /* Add angled drop shadow */
}

.token-info div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    font-weight: bold;
    margin-bottom: 0.5em;
}

.amount {
    font-size: 1.2em;
    color: black; /* Set font color to black */
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
    .hero {
        padding: 1em;
    }

    .bubble-img {
        width: 150px;
    }

    .bubble-text {
        font-size: 1em;
    }

    .cat-image {
        width: 300px;
        top: 45%;
        transform: translate(-50%, -60%);
    }

    .buttons {
        flex-direction: column;
        gap: 0.5em;
        margin-top: 50px;
    }

    .buy-nows, .view-chart {
        padding: 0.5em 0.8em;
    }

    .token-info {
        width: 90%;
    }

    .contract-address {
        padding: 0.5em;
        width: 90%;
        font-size: 0.9em; /* Adjust font size for mobile devices */
    }
}

@media (max-width: 480px) {
    .bubble-img {
        width: 100px;
    }

    .bubble-text {
        font-size: 0.8em;
    }

    .cat-image {
        width: 200px;
        top: 40%;
        transform: translate(-50%, -100%);
    }

    .buttons {
        gap: 0.3em;
        margin-top: 30px;
    }

    .buy-nows, .view-chart {
        padding: 0.4em 0.6em;
    }

    .token-info {
        width: 100%;
        flex-direction: column;
    }

    .token-info div {
        margin-bottom: 0.5em;
    }

    .contract-address {
        padding: 0.4em;
        width: 100%;
        font-size: 0.8em; /* Adjust font size for smaller mobile devices */
    }
}
