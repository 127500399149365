@font-face {
    font-family: 'Wedges';
    src: url('../fonts/Wedges.ttf') format('truetype');
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #b18565;
    padding: 1em 2em;
    border-radius: 60px;
    font-family: 'Wedges', sans-serif;
    max-width: 90%; /* Make it responsive */
    margin: 20px auto; /* Centers the header and adds top margin */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); /* Add shadow for better appearance */
    flex-wrap: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Prevent content overflow */
}

.logo {
    font-size: 2.5em; /* Adjust font size for better layout */
    color: white;
    margin-right: 1em;
    flex-shrink: 0; /* Prevent shrinking */
}

.tagline {
    font-size: 1.5em; /* Adjust font size */
    color: white;
    flex-grow: 1; /* Allow tagline to take available space */
    text-align: center; /* Center the tagline */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Prevent overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

.social-icons {
    display: flex;
    align-items: center;
    margin-left: 1em; /* Add margin for better spacing */
    flex-shrink: 0; /* Prevent shrinking */
}

.social-icons svg {
    margin: 0 0.5em;
    width: 24px; /* Adjust icon size */
    height: 24px; /* Adjust icon size */
    fill: white; /* Set icon color */
}

.buy-now {
    background-color: #00C851;
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Wedges', sans-serif;
    margin-left: 1em; /* Add spacing from social icons */
    transition: background-color 0.3s ease; /* Smooth transition */
    flex-shrink: 0; /* Prevent shrinking */
}

.buy-now:hover {
    background-color: #f1f1f1;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .header {
        max-width: 100%; /* Allow full width on smaller screens */
    }

    .logo {
        font-size: 2em; /* Adjust font size for smaller screens */
    }

    .tagline {
        font-size: 1.2em; /* Adjust font size for smaller screens */
    }

    .social-icons svg {
        width: 20px; /* Adjust icon size */
        height: 20px; /* Adjust icon size */
    }

    .buy-now {
        padding: 0.4em 0.8em; /* Adjust padding for smaller screens */
        font-size: 0.9em; /* Adjust font size */
        background-color: #00C851;
        color: white;
    }
}

@media (max-width: 480px) {
    .logo {
        font-size: 1.5em; /* Adjust font size for smaller screens */
    }

    .tagline {
        font-size: 1em; /* Adjust font size for smaller screens */
    }

    .social-icons svg {
        width: 18px; /* Adjust icon size */
        height: 18px; /* Adjust icon size */
    }

    .buy-now {
        padding: 0.3em 0.5em; /* Adjust padding for smaller screens */
        font-size: 0.8em; /* Adjust font size */
        background-color: #00C851;
        color: white;
    }
}
