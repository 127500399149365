@font-face {
    font-family: 'Wedges';
    src: url('../fonts/Wedges.ttf') format('truetype');
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b18565;
    padding: 0.5em 1em;
    border-radius: 60px;
    font-family: 'Wedges', sans-serif;
    max-width: fit-content; /* Adjust width to fit content */
    margin: 20px auto; /* Centers the footer and adds top margin */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); /* Add shadow for better appearance */
    color: white;
    font-size: 1.5em;
}

.partner-text {
    font-size: 2em;
    margin-right: 1em; /* Add margin to the right of the text */
}

.partners {
    display: flex;
    align-items: center;
}

.partners img {
    margin: 0 0.5em;
    height: auto;
}

.coingecko {
    width: 400px; /* Adjust size as needed */
}

.pumpfun {
    width: 400px; /* Adjust size as needed */
}

.dexscreener {
    width: 120px; /* Adjust size as needed */
}

.dextools {
    width: 80px; /* Adjust size as needed */
    margin-left: 40px;
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 0.5em;
        font-size: 1.2em; /* Adjust font size */
        border-radius: 30px;
    }

    .partner-text {
        font-size: 1.5em;
        margin: 0 0 0.5em 0; /* Margin adjusted for vertical layout */
    }

    .partners {
        flex-direction: column;
    }

    .partners img {
        margin: 0.5em 0; /* Margin adjusted for vertical layout */
    }

    .coingecko,
    .pumpfun {
        width: 300px; /* Adjust size as needed */
    }

    .dexscreener {
        width: 100px; /* Adjust size as needed */
    }

    .dextools {
        width: 60px; /* Adjust size as needed */
        margin: 0.5em 0; /* Margin adjusted for vertical layout */
    }
}

@media (max-width: 480px) {
    .footer {
        flex-direction: column;
        padding: 0.5em;
        font-size: 1em; /* Adjust font size */
        border-radius: 30px;
    }

    .partner-text {
        font-size: 1.2em;
        margin: 0 0 0.5em 0; /* Margin adjusted for vertical layout */
    }

    .partners {
        flex-direction: column;
    }

    .partners img {
        margin: 0.5em 0; /* Margin adjusted for vertical layout */
    }

    .coingecko,
    .pumpfun {
        width: 250px; /* Adjust size as needed */
    }

    .dexscreener {
        width: 80px; /* Adjust size as needed */
    }

    .dextools {
        width: 50px; /* Adjust size as needed */
        margin: 0.5em 0; /* Margin adjusted for vertical layout */
    }
    .season {
        width: 55px; /* Adjust size as needed */
        margin: 0.5em 0; /* Margin adjusted for vertical layout */
    }
}
