.video-carousel {
    width: 100%;
    max-width: 1000px; /* Set a max-width for larger display */
    margin: 0 auto; /* Center the carousel */
    padding: 40px 0; /* Add padding to the top and bottom */
    text-align: center; /* Center-align all text inside the video carousel */
  }
  
  .carousel-header {
    font-size: 3em; /* Make the header larger */
    color: white; /* Set the header color to white */
    margin-bottom: 20px; /* Add some margin below the header */
  }
  
  .video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .video-wrapper iframe {
    width: 100%;
    height: 500px; /* Set a fixed height for the iframe */
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 1024px) {
    .video-wrapper iframe {
      height: 400px; /* Adjust height for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .video-carousel {
      width: 95%;
    }
  
    .carousel-header {
      font-size: 2.5em; /* Adjust font size for smaller screens */
    }
  
    .video-wrapper iframe {
      height: 300px; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .video-carousel {
      width: 100%;
      padding: 20px 0;
    }
  
    .carousel-header {
      font-size: 2em; /* Adjust font size for mobile screens */
    }
  
    .video-wrapper iframe {
      height: 200px; /* Adjust height for mobile screens */
    }
  }
  