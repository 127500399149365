@font-face {
    font-family: 'Wedges';
    src: url('../fonts/Wedges.ttf') format('truetype');
}

.choose-pfp {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2em;
    font-family: 'Wedges', sans-serif;
    font-size: 2em;
    margin: 20px auto;
    width: 80%;
    color: #fff;
}

.header1 {
    font-size: 3em;
    text-shadow: 5px 5px 15px rgba(245, 220, 188, 0.8);
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Adjust the number of columns as needed */
    gap: 10px;
    margin-top: 20px;
}

.grid img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.more-memes {
    background-color: #78cf96; /* Mint green color */
    color: white;
    border: none;
    padding: 0.5em 1em;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Wedges', sans-serif;
    font-size: 1.25em;
    margin-top: 80px;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.more-memes:hover {
    background-color: #2ed1b0; /* Slightly darker mint green on hover */
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .choose-pfp {
        padding: 1em;
        font-size: 1.5em;
        width: 90%;
    }

    .header1 {
        font-size: 2.5em;
    }

    .grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 5px; /* Smaller gap for medium screens */
    }

    .grid img {
        width: 90%; /* Adjust image size for smaller screens */
    }

    .more-memes {
        font-size: 1em;
        margin-top: 50px;
    }
}

@media (max-width: 480px) {
    .choose-pfp {
        padding: 0.5em;
        font-size: 1.2em;
        width: 100%;
    }

    .header1 {
        font-size: 2em;
    }

    .grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 3px; /* Smaller gap for small screens */
    }

    .grid img {
        width: 90%; /* Further adjust image size for smaller screens */
    }

    .more-memes {
        font-size: 0.8em;
        margin-top: 30px;
    }
}
